import React from 'react';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Info as InfoIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { Photo } from '../types/api';

interface PhotoGridProps {
  photos: Photo[];
  onPhotoClick: (photo: Photo) => void;
  onPhotoDelete?: (photo: Photo) => void;
  selectable?: boolean;
  selectedPhotos?: Set<string>;
  onPhotoSelect?: (photo: Photo) => void;
}

export default function PhotoGrid({
  photos,
  onPhotoClick,
  onPhotoDelete,
  selectable = false,
  selectedPhotos = new Set(),
  onPhotoSelect,
}: PhotoGridProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const getCols = () => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    return 3;
  };

  return (
    <ImageList
      cols={getCols()}
      gap={16}
      sx={{
        mb: 2,
        '& .MuiImageListItem-root': {
          overflow: 'hidden',
          borderRadius: 1,
        },
      }}
    >
      {photos.map((photo) => (
        <ImageListItem
          key={photo.id}
          onClick={() => onPhotoSelect ? onPhotoSelect(photo) : onPhotoClick(photo)}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              '& .MuiImageListItemBar-root': {
                opacity: 1,
              },
            },
            border: selectedPhotos.has(photo.id)
              ? `2px solid ${theme.palette.primary.main}`
              : 'none',
          }}
        >
          <img
            src={photo.thumbnailUrl}
            alt={photo.title}
            loading="lazy"
            style={{
              height: '200px',
              width: '100%',
              objectFit: 'cover',
            }}
          />
          <ImageListItemBar
            title={photo.title}
            sx={{
              opacity: 0,
              transition: 'opacity 0.2s',
              background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
            }}
            actionIcon={
              <Box>
                <IconButton
                  sx={{ color: 'white' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onPhotoClick(photo);
                  }}
                >
                  <InfoIcon />
                </IconButton>
                {onPhotoDelete && (
                  <IconButton
                    sx={{ color: 'white' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onPhotoDelete(photo);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
} 
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  IconButton,
  Typography,
  CircularProgress,
  useTheme,
} from '@mui/material';
import { ArrowBack, Event } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { photoApi } from '../api/client';
import PhotoSocialActions from '../components/PhotoSocialActions';
import { format } from 'date-fns';

export default function PhotoDetailPage() {
  const { photoId } = useParams<{ photoId: string }>();
  const navigate = useNavigate();
  const theme = useTheme();

  const { data: photo, isLoading, error } = useQuery(
    ['photo', photoId],
    () => photoId ? photoApi.getPhoto(photoId) : null,
    {
      enabled: !!photoId,
    }
  );

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error || !photo) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          p: 3,
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Failed to load photo
        </Typography>
        <Typography color="text.secondary">
          The photo might have been deleted or you may not have permission to view it.
        </Typography>
        <Box sx={{ mt: 2 }}>
          <IconButton onClick={() => navigate(-1)} color="primary">
            <ArrowBack />
          </IconButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: '100%',
        margin: '0 auto',
        p: { xs: 0, sm: 2 },
        minHeight: '100vh',
        bgcolor: theme.palette.background.default,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          maxWidth: 1200,
          margin: '0 auto',
          overflow: 'hidden',
          bgcolor: 'background.paper',
          borderRadius: { xs: 0, sm: 1 },
        }}
      >
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" sx={{ flex: 1 }}>
            {photo.title || 'Photo Details'}
          </Typography>
          {photo.albumId && (
            <IconButton onClick={() => navigate(`/albums/${photo.albumId}`)}>
              <Event />
            </IconButton>
          )}
        </Box>

        <Box
          component="img"
          src={photo.blobUrl}
          alt={photo.title || 'Photo'}
          sx={{
            width: '100%',
            height: 'auto',
            maxHeight: 'calc(100vh - 200px)',
            objectFit: 'contain',
            bgcolor: 'black',
          }}
        />

        <Box sx={{ p: 2 }}>
          {photo.description && (
            <Typography variant="body1" gutterBottom>
              {photo.description}
            </Typography>
          )}
          <Typography variant="caption" color="text.secondary" component="div">
            Taken on {format(new Date(photo.takenAt), 'PPpp')}
          </Typography>
          {photo.location && (
            <Typography variant="caption" color="text.secondary" component="div">
              Location: {photo.location}
            </Typography>
          )}
        </Box>

        <Box sx={{ bgcolor: theme.palette.background.paper, borderRadius: 1 }}>
          <PhotoSocialActions photo={photo} />
        </Box>
      </Paper>
    </Box>
  );
} 
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@mui/material';
import { Album } from '../types/api';

interface CreateAlbumDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (album: Omit<Album, 'id' | 'eventId' | 'photoIds' | 'createdAt' | 'updatedAt'>) => void;
}

export default function CreateAlbumDialog({ open, onClose, onSubmit }: CreateAlbumDialogProps) {
  const [album, setAlbum] = useState<Partial<Album>>({
    name: '',
    description: '',
    coverPhotoId: '',
  });

  const handleSubmit = () => {
    onSubmit(album as Omit<Album, 'id' | 'eventId' | 'photoIds' | 'createdAt' | 'updatedAt'>);
    setAlbum({ name: '', description: '', coverPhotoId: '' });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create New Album</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
          <TextField
            label="Album Name"
            value={album.name}
            onChange={(e) => setAlbum({ ...album, name: e.target.value })}
            fullWidth
            required
          />
          <TextField
            label="Description"
            value={album.description}
            onChange={(e) => setAlbum({ ...album, description: e.target.value })}
            multiline
            rows={3}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!album.name}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
} 
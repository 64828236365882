import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Album, Photo } from '../types/api';
import { albumApi, eventApi, photoApi } from '../api/client';

interface AlbumManagementDialogProps {
  open: boolean;
  onClose: () => void;
  eventId: string;
  currentAlbumId?: string;
  selectedPhotos: Photo[];
}

export default function AlbumManagementDialog({
  open,
  onClose,
  eventId,
  currentAlbumId,
  selectedPhotos,
}: AlbumManagementDialogProps) {
  const [targetAlbumId, setTargetAlbumId] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { data: albums, isLoading: isLoadingAlbums } = useQuery(
    ['eventAlbums', eventId],
    () => eventApi.getEventAlbums(eventId),
    {
      enabled: open,
    }
  );

  const movePhotosMutation = useMutation(
    async () => {
      if (!targetAlbumId) throw new Error('Please select a target album');
      
      // Move each photo to the new album
      await Promise.all(
        selectedPhotos.map(photo =>
          photoApi.movePhoto(photo.id, targetAlbumId)
        )
      );
    },
    {
      onSuccess: () => {
        // Invalidate relevant queries
        queryClient.invalidateQueries(['albumPhotos', currentAlbumId]);
        queryClient.invalidateQueries(['albumPhotos', targetAlbumId]);
        queryClient.invalidateQueries(['eventAlbums', eventId]);
        onClose();
      },
      onError: (error: Error) => {
        setError(error.message);
      },
    }
  );

  const handleMove = async () => {
    try {
      await movePhotosMutation.mutateAsync();
    } catch (error) {
      // Error is handled by the mutation
    }
  };

  const handleAlbumChange = (event: SelectChangeEvent<string>) => {
    setTargetAlbumId(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Move Photos to Album</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Typography variant="subtitle1" gutterBottom>
            Selected Photos: {selectedPhotos.length}
          </Typography>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Target Album</InputLabel>
            <Select
              value={targetAlbumId}
              onChange={handleAlbumChange}
              label="Target Album"
            >
              {albums?.filter((album: Album) => album.id !== currentAlbumId).map((album: Album) => (
                <MenuItem key={album.id} value={album.id}>
                  {album.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ mt: 2 }}>
            <Typography variant="caption" color="textSecondary">
              Selected photos will be moved to the target album. This action cannot be undone.
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleMove}
          variant="contained"
          disabled={!targetAlbumId || movePhotosMutation.isLoading}
        >
          {movePhotosMutation.isLoading ? (
            <>
              Moving...
              <CircularProgress size={20} sx={{ ml: 1 }} />
            </>
          ) : (
            'Move Photos'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
} 
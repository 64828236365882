import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import {
  Box,
  Paper,
  Typography,
  Button,
  Divider,
  Dialog,
  CircularProgress,
  LinearProgress,
  Backdrop,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  PhotoCamera as PhotoCameraIcon,
} from '@mui/icons-material';
import { CameraCapture } from './CameraCapture';

interface PhotoUploadProps {
  onUpload: (files: File[]) => Promise<void>;
}

export default function PhotoUpload({
  onUpload,
}: PhotoUploadProps) {
  const [showCamera, setShowCamera] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      setIsUploading(true);
      await onUpload(acceptedFiles);
      enqueueSnackbar('Photos uploaded successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error uploading files:', error);
      enqueueSnackbar('Failed to upload files', { variant: 'error' });
    } finally {
      setIsUploading(false);
    }
  }, [onUpload, enqueueSnackbar]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.heic']
    },
    disabled: isUploading,
  });

  const handleCameraCapture = async (imageData: string) => {
    try {
      setIsUploading(true);
      // Convert base64 to blob
      const base64Data = imageData.split(',')[1];
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
      
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      
      const blob = new Blob(byteArrays, { type: 'image/jpeg' });
      const file = new File([blob], `photo_${Date.now()}.jpg`, { type: 'image/jpeg' });
      await onUpload([file]);
      enqueueSnackbar('Photo captured and uploaded successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error processing camera capture:', error);
      enqueueSnackbar('Failed to process camera capture', { variant: 'error' });
    } finally {
      setIsUploading(false);
      setShowCamera(false);
    }
  };

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          position: 'relative',
        }}
      >
        {isUploading && (
          <LinearProgress
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              borderTopLeftRadius: 'inherit',
              borderTopRightRadius: 'inherit',
            }}
          />
        )}
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="outlined"
            startIcon={isUploading ? <CircularProgress size={20} /> : <PhotoCameraIcon />}
            onClick={() => setShowCamera(true)}
            disabled={isUploading}
          >
            Take Photo
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button
            variant="outlined"
            startIcon={isUploading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
            {...getRootProps()}
            disabled={isUploading}
          >
            <input {...getInputProps()} />
            {isDragActive ? 'Drop files here' : 'Upload Photos'}
          </Button>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {isUploading ? 'Uploading...' : 'Supported formats: JPEG, PNG, GIF, HEIC'}
        </Typography>
      </Paper>

      {showCamera && (
        <Dialog
          open={showCamera}
          onClose={() => !isUploading && setShowCamera(false)}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              height: '80vh',
              maxHeight: '600px',
            },
          }}
        >
          <CameraCapture
            onCapture={handleCameraCapture}
            onClose={() => !isUploading && setShowCamera(false)}
          />
        </Dialog>
      )}

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
} 
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { setAuthToken, setupTokenRefresh, clearAuthToken } from '../api/client';

interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  error: Error | null;
  user: {
    name: string;
    email: string;
    username: string;
  } | null;
  acquireToken: () => Promise<string>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isLoading: true,
  error: null,
  user: null,
  acquireToken: async () => '',
  logout: async () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
  onLogout?: () => void;
}

export function AuthProvider({ children, onLogout }: AuthProviderProps) {
  const { instance, accounts, inProgress } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [user, setUser] = useState<AuthContextType['user']>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const acquireToken = async () => {
    if (accounts.length === 0) {
      setIsLoading(false);
      return '';
    }

    try {
      const silentRequest = {
        scopes: [process.env.REACT_APP_AZURE_AD_SCOPES ?? ''],
        account: accounts[0],
      };

      let authResult: AuthenticationResult;
      try {
        authResult = await instance.acquireTokenSilent(silentRequest);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          authResult = await instance.acquireTokenPopup(silentRequest);
        } else {
          throw error;
        }
      }

      setAuthToken(authResult.accessToken);
      return authResult.accessToken;
    } catch (error) {
      setError(error as Error);
      console.error('Failed to acquire token:', error);
      return '';
    }
  };

  const logout = async () => {
    try {
      clearAuthToken();
      setUser(null);
      onLogout?.();
      await instance.logoutRedirect();
    } catch (error) {
      console.error('Logout failed:', error);
      onLogout?.();
    }
  };

  // Set up token refresh interceptor only once
  useEffect(() => {
    if (!isInitialized) {
      setupTokenRefresh(acquireToken);
      setIsInitialized(true);
    }
  }, [isInitialized]);

  // Handle authentication state changes
  useEffect(() => {
    const initializeAuth = async () => {
      // Only proceed if MSAL is ready
      if (inProgress === 'none') {
        if (accounts.length > 0) {
          const account = accounts[0];
          setUser({
            name: account.name ?? '',
            email: account.username,
            username: account.username,
          });
          try {
            await acquireToken();
          } catch (error) {
            console.error('Failed to initialize auth:', error);
            setError(error as Error);
          }
        }
        setIsLoading(false);
      }
    };

    initializeAuth();
  }, [accounts, inProgress]);

  // Set up token refresh interval
  useEffect(() => {
    if (!isLoading && accounts.length > 0) {
      const refreshInterval = setInterval(async () => {
        await acquireToken();
      }, 15 * 60 * 1000); // Refresh token every 15 minutes

      return () => clearInterval(refreshInterval);
    }
  }, [isLoading, accounts]);

  const contextValue: AuthContextType = {
    isAuthenticated: accounts.length > 0,
    isLoading: isLoading || inProgress !== 'none',
    error,
    user,
    acquireToken,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
} 
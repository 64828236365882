import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Box, Button, IconButton, CircularProgress, Typography, Alert } from '@mui/material';
import { PhotoCamera, Replay, CameraFront, CameraRear } from '@mui/icons-material';
import Webcam from 'react-webcam';

interface CameraCaptureProps {
  onCapture: (imageData: string) => void;
  onClose: () => void;
}

const MOBILE_CONSTRAINTS = {
  width: { ideal: 2048 },
  height: { ideal: 1536 },
  facingMode: { exact: "user" },
  aspectRatio: 4/3
};

const DESKTOP_CONSTRAINTS = {
  width: { ideal: 2048 },
  height: { ideal: 1536 },
  facingMode: "user",
  aspectRatio: 4/3
};

export const CameraCapture: React.FC<CameraCaptureProps> = ({ onCapture, onClose }) => {
  const webcamRef = useRef<Webcam>(null);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMobile] = useState(() => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  const [facingMode, setFacingMode] = useState<"user" | "environment">("user");
  const [isInitializing, setIsInitializing] = useState(true);

  const videoConstraints = isMobile ? {
    ...MOBILE_CONSTRAINTS,
    facingMode: { exact: facingMode }
  } : DESKTOP_CONSTRAINTS;

  useEffect(() => {
    // Store current body style
    const originalStyle = {
      overflow: document.body.style.overflow,
      position: document.body.style.position,
      width: document.body.style.width,
      height: document.body.style.height,
      touchAction: document.body.style.touchAction
    };

    // Prevent scrolling
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.height = '100%';
    document.body.style.touchAction = 'none';

    // Reset to front camera and add delay to allow camera initialization
    setFacingMode("user");
    const timer = setTimeout(() => {
      setIsInitializing(false);
    }, 1000);

    return () => {
      // Restore original body style
      document.body.style.overflow = originalStyle.overflow;
      document.body.style.position = originalStyle.position;
      document.body.style.width = originalStyle.width;
      document.body.style.height = originalStyle.height;
      document.body.style.touchAction = originalStyle.touchAction;
      clearTimeout(timer);
    };
  }, []);

  const handleError = (err: string) => {
    console.error('Camera error:', err);
    setError(err);
    setIsInitializing(false);
  };

  const switchCamera = () => {
    setIsInitializing(true);
    setFacingMode(prev => prev === "user" ? "environment" : "user");
    setError(null);
  };

  const capture = useCallback(async () => {
    if (webcamRef.current && !isCapturing) {
      setIsCapturing(true);
      setError(null);
      try {
        // Force maximum resolution for the screenshot while maintaining aspect ratio
        const imageSrc = webcamRef.current.getScreenshot({
          width: 2048,
          height: 1536
        });
        if (imageSrc) {
          setImgSrc(imageSrc);
        } else {
          throw new Error("Failed to capture image");
        }
      } catch (err) {
        handleError("Failed to capture photo. Please try again.");
      } finally {
        setIsCapturing(false);
      }
    }
  }, [webcamRef, isCapturing]);

  const retake = () => {
    setImgSrc(null);
    setError(null);
  };

  const confirmCapture = async () => {
    if (imgSrc && !isProcessing) {
      setIsProcessing(true);
      try {
        await onCapture(imgSrc);
        onClose();
      } catch (err) {
        handleError("Failed to process photo. Please try again.");
      } finally {
        setIsProcessing(false);
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        position: 'relative',
        overflow: 'hidden',
        touchAction: 'none',
      }}
    >
      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            position: 'absolute', 
            top: 16, 
            left: 16, 
            right: 16, 
            zIndex: 1100 
          }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      <Box
        sx={{
          flex: 1,
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isInitializing ? (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            gap: 2 
          }}>
            <CircularProgress />
            <Typography color="white">Initializing camera...</Typography>
          </Box>
        ) : imgSrc ? (
          <img 
            src={imgSrc} 
            alt="captured" 
            style={{ 
              maxWidth: '100%', 
              maxHeight: 'calc(100vh - 120px)',
              objectFit: 'contain',
            }} 
          />
        ) : (
          <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
              videoConstraints={videoConstraints}
              onUserMediaError={(err) => handleError("Failed to access camera. Please check permissions.")}
              style={{ 
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              mirrored={facingMode === "user"}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(10px)',
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          zIndex: 1000,
        }}
      >
        {imgSrc ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={confirmCapture}
              disabled={isProcessing}
              sx={{ minWidth: 120 }}
            >
              {isProcessing ? (
                <>
                  Processing...
                  <CircularProgress size={20} sx={{ ml: 1 }} />
                </>
              ) : (
                'Use Photo'
              )}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={retake}
              disabled={isProcessing}
              startIcon={<Replay />}
              sx={{ minWidth: 120 }}
            >
              Retake
            </Button>
          </>
        ) : (
          <>
            {isMobile && (
              <IconButton
                onClick={switchCamera}
                sx={{
                  width: 48,
                  height: 48,
                  backgroundColor: 'white',
                  '&:hover': {
                    backgroundColor: 'grey.100',
                  },
                }}
              >
                {facingMode === "user" ? <CameraRear /> : <CameraFront />}
              </IconButton>
            )}
            <IconButton
              color="primary"
              onClick={capture}
              disabled={isCapturing || isInitializing}
              sx={{
                width: 64,
                height: 64,
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'grey.100',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isCapturing ? (
                <CircularProgress size={32} />
              ) : (
                <PhotoCamera sx={{ fontSize: 32 }} />
              )}
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
}; 
import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Avatar,
  CardHeader,
  Skeleton,
  Divider,
} from '@mui/material';
import { format } from 'date-fns';
import { eventApi, albumApi, photoApi } from '../api/client';
import { Event, Photo, Album } from '../types/api';
import { useNavigate } from 'react-router-dom';
import PhotoSocialActions from '../components/PhotoSocialActions';

interface EnhancedPhoto extends Photo {
  event: Event;
  album?: Album;
}

export default function FeedPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [page] = useState(1);

  const { data: events } = useQuery<Event[]>(['events', 'ongoing'], 
    () => eventApi.getEvents('ongoing')
  );

  const { data: recentPhotos, isLoading: photosLoading } = useQuery<EnhancedPhoto[]>(
    ['photos', 'recent', page],
    async () => {
      // Get photos from ongoing events
      if (!events?.length) return [];
      
      const eventPhotos = await Promise.all(
        events.map(async (event) => {
          const albums = await eventApi.getEventAlbums(event.id);
          const photos = await Promise.all(
            albums.map(album => albumApi.getAlbumPhotos(album.id))
          );
          return photos.flat().map((photo: Photo) => ({
            ...photo,
            event,
            album: albums.find(a => a.id === photo.albumId),
          }));
        })
      );

      // Flatten and sort by date
      return eventPhotos
        .flat()
        .sort((a, b) => 
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
    },
    {
      enabled: !!events?.length,
      refetchOnWindowFocus: false,
      staleTime: 30000, // Consider data fresh for 30 seconds
    }
  );

  const eventsLoading = !events;

  useEffect(() => {
    const handlePhotoUploaded = () => {
      queryClient.invalidateQueries(['photos', 'recent']);
    };

    const handlePhotoInteraction = (message: { photoId: string }) => {
      if (message.photoId) {
        queryClient.invalidateQueries(['photo', message.photoId]);
        queryClient.invalidateQueries(['photos', 'recent']);
      }
    };

    // Subscribe to SignalR events
    const signalR = (window as any).signalR;
    if (signalR && signalR.connection) {
      signalR.connection.on('PhotoUploaded', handlePhotoUploaded);
      signalR.connection.on('PhotoLiked', handlePhotoInteraction);
      signalR.connection.on('PhotoUnliked', handlePhotoInteraction);
      signalR.connection.on('PhotoCommented', handlePhotoInteraction);
      signalR.connection.on('PhotoCommentDeleted', handlePhotoInteraction);
      signalR.connection.on('PhotoShared', handlePhotoInteraction);

      return () => {
        signalR.connection.off('PhotoUploaded', handlePhotoUploaded);
        signalR.connection.off('PhotoLiked', handlePhotoInteraction);
        signalR.connection.off('PhotoUnliked', handlePhotoInteraction);
        signalR.connection.off('PhotoCommented', handlePhotoInteraction);
        signalR.connection.off('PhotoCommentDeleted', handlePhotoInteraction);
        signalR.connection.off('PhotoShared', handlePhotoInteraction);
      };
    }
  }, [queryClient]);

  const isLoading = eventsLoading || photosLoading;

  if (isLoading) {
    return (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {[1, 2, 3].map((i) => (
            <Grid item xs={12} key={i}>
              <Card>
                <CardHeader
                  avatar={<Skeleton variant="circular" width={40} height={40} />}
                  title={<Skeleton variant="text" width={120} />}
                  subheader={<Skeleton variant="text" width={80} />}
                />
                <Skeleton variant="rectangular" height={300} />
                <CardContent>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" width="60%" />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, height: '100%', overflow: 'auto' }}>
      <Grid container spacing={2}>
        {recentPhotos?.map((photo: EnhancedPhoto) => (
          <Grid item xs={12} key={photo.id}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    {photo.event.name[0].toUpperCase()}
                  </Avatar>
                }
                title={photo.event.name}
                subheader={format(new Date(photo.createdAt), 'PPp')}
                onClick={() => navigate(`/events/${photo.event.id}`)}
                sx={{ cursor: 'pointer' }}
              />
              <CardMedia
                component="img"
                image={photo.blobUrl}
                alt={photo.title || 'Event photo'}
                sx={{
                  height: 'auto',
                  maxHeight: '500px',
                  objectFit: 'contain',
                  bgcolor: 'black',
                  cursor: 'pointer',
                }}
                onClick={() => navigate(`/photos/${photo.id}`)}
              />
              {photo.description && (
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {photo.description}
                  </Typography>
                </CardContent>
              )}
              <Divider />
              <PhotoSocialActions photo={photo} />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
} 
import { useState } from 'react';
import { photoApi } from '../api/client';
import { useQueryClient } from 'react-query';

export function usePhotoUpload(eventId: string, albumId?: string) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const queryClient = useQueryClient();

  const uploadPhotos = async (files: File[]) => {
    setIsUploading(true);
    setUploadProgress(0);

    try {
      const totalFiles = files.length;
      let completedFiles = 0;

      for (const file of files) {
        const photo = await photoApi.uploadPhoto(eventId, file);
        if (albumId) {
          await photoApi.movePhoto(photo.id, albumId);
        }
        completedFiles++;
        setUploadProgress((completedFiles / totalFiles) * 100);
      }

      // Invalidate relevant queries
      await queryClient.invalidateQueries(['eventAlbums', eventId]);
      if (albumId) {
        await queryClient.invalidateQueries(['albumPhotos', albumId]);
      }
      
      setUploadProgress(100);
      setTimeout(() => {
        setIsUploading(false);
        setUploadProgress(0);
      }, 1000);
    } catch (error) {
      console.error('Failed to upload photos:', error);
      setIsUploading(false);
      setUploadProgress(0);
      throw error;
    }
  };

  return {
    isUploading,
    uploadProgress,
    uploadPhotos,
  };
} 
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Skeleton,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { format } from 'date-fns';
import { eventApi } from '../api/client';
import { Event } from '../types/api';
import { useNavigate } from 'react-router-dom';
import CreateEventDialog from '../components/CreateEventDialog';

type EventType = 'past' | 'ongoing' | 'upcoming';

export default function EventsPage() {
  const [selectedTab, setSelectedTab] = useState<EventType>('ongoing');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const navigate = useNavigate();

  const { data: events, isLoading } = useQuery<Event[]>(
    ['events', selectedTab],
    () => eventApi.getEvents(selectedTab)
  );

  const handleTabChange = (_: React.SyntheticEvent, newValue: EventType) => {
    setSelectedTab(newValue);
  };

  const handleCreateEvent = async (event: Omit<Event, 'id' | 'albumIds' | 'createdAt' | 'updatedAt'>) => {
    try {
      const newEvent = await eventApi.createEvent(event);
      navigate(`/events/${newEvent.id}`);
    } catch (error) {
      console.error('Failed to create event:', error);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">
          Events
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsCreateDialogOpen(true)}
        >
          Create Event
        </Button>
      </Box>

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{ mb: 3 }}
        variant="fullWidth"
      >
        <Tab label="Past" value="past" />
        <Tab label="Ongoing" value="ongoing" />
        <Tab label="Upcoming" value="upcoming" />
      </Tabs>

      {isLoading ? (
        <Grid container spacing={2}>
          {[1, 2, 3].map((i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Card>
                <CardContent>
                  <Skeleton variant="text" width="60%" height={32} />
                  <Skeleton variant="text" width="40%" />
                  <Skeleton variant="text" width="80%" />
                </CardContent>
                <CardActions>
                  <Skeleton variant="rectangular" width={80} height={36} />
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {events?.map((event) => (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: (theme) => theme.shadows[4],
                  },
                }}
                onClick={() => navigate(`/events/${event.id}`)}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    {event.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {format(new Date(event.startTime), 'PPp')} -{' '}
                    {format(new Date(event.endTime), 'PPp')}
                  </Typography>
                  {event.location && (
                    <Typography variant="body2" color="text.secondary">
                      Location: {event.location}
                    </Typography>
                  )}
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary">
                    View Details
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
          {events?.length === 0 && (
            <Grid item xs={12}>
              <Typography variant="body1" color="text.secondary" align="center">
                No {selectedTab} events found
              </Typography>
            </Grid>
          )}
        </Grid>
      )}

      <CreateEventDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onSubmit={handleCreateEvent}
      />
    </Box>
  );
} 
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { SnackbarProvider } from 'notistack';

import { msalConfig } from './auth/msalConfig';
import { AuthProvider } from './contexts/AuthContext';
import { DateTimeProvider } from './contexts/DateTimeProvider';
import { ThemeProvider } from './contexts/ThemeContext';
import Layout from './components/Layout';
import FeedPage from './pages/FeedPage';
import EventsPage from './pages/EventsPage';
import EventDetailPage from './pages/EventDetailPage';
import AlbumPage from './pages/AlbumPage';
import PhotoDetailPage from './pages/PhotoDetailPage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import { NotificationProvider } from './contexts/NotificationContext';

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error: any) => {
        // Retry on network errors or 401s (which will trigger token refresh)
        if (error?.response?.status === 401) return true;
        if (!error?.response) return true; // Network error
        return false;
      },
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

function AppContent() {
  return (
    <AuthProvider>
      <DateTimeProvider>
        <NotificationProvider>
          <UnauthenticatedTemplate>
            <LoginPage />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Layout>
              <Routes>
                <Route path="/" element={<FeedPage />} />
                <Route path="/events" element={<EventsPage />} />
                <Route path="/events/:eventId" element={<EventDetailPage />} />
                <Route path="/albums/:albumId" element={<AlbumPage />} />
                <Route path="/photos/:photoId" element={<PhotoDetailPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Layout>
          </AuthenticatedTemplate>
        </NotificationProvider>
      </DateTimeProvider>
    </AuthProvider>
  );
}

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            autoHideDuration={3000}
          >
            <BrowserRouter>
              <AppContent />
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </MsalProvider>
  );
}

export default App;

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueries } from 'react-query';
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Paper,
} from '@mui/material';
import {
  Album as AlbumIcon,
  AutoAwesome as AutoAwesomeIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { eventApi, albumApi } from '../api/client';
import { useNotifications } from '../contexts/NotificationContext';
import CreateAlbumDialog from '../components/CreateAlbumDialog';
import PhotoUpload from '../components/PhotoUpload';
import { usePhotoUpload } from '../hooks/usePhotoUpload';
import { Album } from '../types/api';

export default function EventDetailPage() {
  const { eventId = '' } = useParams<{ eventId: string }>();
  const navigate = useNavigate();
  const { joinEventGroup, leaveEventGroup } = useNotifications();
  const [isCreateAlbumDialogOpen, setIsCreateAlbumDialogOpen] = useState(false);

  const { data: event } = useQuery(
    ['event', eventId],
    () => eventApi.getEvent(eventId),
    {
      enabled: Boolean(eventId),
    }
  );

  const { data: albums, refetch: refetchAlbums } = useQuery(
    ['eventAlbums', eventId],
    () => eventApi.getEventAlbums(eventId),
    {
      enabled: Boolean(eventId),
    }
  );

  // Use useQueries for parallel album photos queries
  const albumPhotosQueries = useQueries(
    (albums || []).map(album => ({
      queryKey: ['albumPhotos', album.id],
      queryFn: () => albumApi.getAlbumPhotos(album.id),
      enabled: Boolean(album.id),
    }))
  );

  const { isUploading, uploadProgress, uploadPhotos } = usePhotoUpload(eventId);

  React.useEffect(() => {
    if (eventId) {
      joinEventGroup(eventId);
      return () => {
        leaveEventGroup(eventId);
      };
    }
  }, [eventId, joinEventGroup, leaveEventGroup]);

  if (!eventId) {
    navigate('/events');
    return null;
  }

  const handleCreateAlbum = async (album: Omit<Album, 'id' | 'eventId' | 'photoIds' | 'createdAt' | 'updatedAt'>) => {
    try {
      await eventApi.createEventAlbum(eventId, album);
      refetchAlbums();
      setIsCreateAlbumDialogOpen(false);
    } catch (error) {
      console.error('Failed to create album:', error);
    }
  };

  const handleOrganizePhotos = async () => {
    try {
      await eventApi.organizeEventPhotos(eventId);
      refetchAlbums();
    } catch (error) {
      console.error('Failed to organize photos:', error);
    }
  };

  if (!event) {
    return <Typography>Loading event...</Typography>;
  }

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <IconButton onClick={() => navigate('/events')} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" component="h1" display="inline">
          {event.name}
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Event Details
            </Typography>
            <Typography color="textSecondary" paragraph>
              {event.description}
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              Location: {event.location}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {format(new Date(event.startTime), 'PPP p')} -{' '}
              {format(new Date(event.endTime), 'PPP p')}
            </Typography>
          </Paper>

          <Typography variant="h6" gutterBottom>
            Albums
          </Typography>
          <Grid container spacing={2}>
            {albums?.map((album, index) => {
              const photosQuery = albumPhotosQueries[index];
              const photos = photosQuery?.data || [];
              const coverPhoto = photos.find(p => p.id === album.coverPhotoId) || photos[0];
              
              return (
                <Grid item xs={12} sm={6} md={4} key={album.id}>
                  <Card
                    onClick={() => navigate(`/albums/${album.id}`)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={coverPhoto ? coverPhoto.thumbnailUrl : '/placeholder.jpg'}
                      alt={album.name}
                    />
                    <CardContent>
                      <Typography variant="h6">{album.name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {photos.length} photos
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <PhotoUpload
            onUpload={uploadPhotos}
          />

          <Button
            variant="contained"
            startIcon={<AlbumIcon />}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
            onClick={() => setIsCreateAlbumDialogOpen(true)}
          >
            Create New Album
          </Button>

          <Button
            variant="outlined"
            startIcon={<AutoAwesomeIcon />}
            fullWidth
            onClick={handleOrganizePhotos}
          >
            Auto-Organize Photos
          </Button>
        </Grid>
      </Grid>

      <CreateAlbumDialog
        open={isCreateAlbumDialogOpen}
        onClose={() => setIsCreateAlbumDialogOpen(false)}
        onSubmit={handleCreateAlbum}
      />
    </Box>
  );
} 
import React, { useCallback, useRef, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  Fab,
  Slide,
  CircularProgress,
  Alert,
} from '@mui/material';
import {
  PhotoCamera,
  Replay,
  PhotoLibrary,
  Close as CloseIcon,
  Cameraswitch as CameraswitchIcon,
} from '@mui/icons-material';
import Webcam from 'react-webcam';

interface PhotoCaptureMenuProps {
  open: boolean;
  onClose: () => void;
  onCapture: (files: File[]) => Promise<void>;
}

const MOBILE_CONSTRAINTS = {
  width: { ideal: 2048 },
  height: { ideal: 1536 },
  facingMode: { exact: "environment" },
  aspectRatio: 4/3
};

const DESKTOP_CONSTRAINTS = {
  width: { ideal: 2048 },
  height: { ideal: 1536 },
  facingMode: "user",
  aspectRatio: 4/3
};

export default function PhotoCaptureMenu({ open, onClose, onCapture }: PhotoCaptureMenuProps) {
  const webcamRef = useRef<Webcam>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMobile] = useState(() => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  const [facingMode, setFacingMode] = useState<"user" | "environment">("user");
  const [isInitializing, setIsInitializing] = useState(true);

  const videoConstraints = isMobile ? {
    width: { ideal: 2048 },
    height: { ideal: 1536 },
    facingMode: { exact: facingMode }
  } : DESKTOP_CONSTRAINTS;

  // Handle body scroll locking
  useEffect(() => {
    if (open) {
      // Store current body style
      const originalStyle = {
        overflow: document.body.style.overflow,
        position: document.body.style.position,
        width: document.body.style.width,
        height: document.body.style.height,
        touchAction: document.body.style.touchAction
      };

      // Prevent scrolling
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.height = '100%';
      document.body.style.touchAction = 'none';

      return () => {
        // Restore original body style
        document.body.style.overflow = originalStyle.overflow;
        document.body.style.position = originalStyle.position;
        document.body.style.width = originalStyle.width;
        document.body.style.height = originalStyle.height;
        document.body.style.touchAction = originalStyle.touchAction;
      };
    }
  }, [open]);

  // Reset state when dialog opens
  useEffect(() => {
    if (open) {
      setImgSrc(null);
      setError(null);
      setIsInitializing(true);
      setFacingMode("user");
      const timer = setTimeout(() => {
        setIsInitializing(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [open]);

  const handleError = (err: string) => {
    console.error('Camera error:', err);
    setError(err);
    setIsInitializing(false);
  };

  const handleSwitchCamera = useCallback(() => {
    setIsInitializing(true);
    setFacingMode(prev => prev === "user" ? "environment" : "user");
    setError(null);
    // Add delay to allow camera to switch
    setTimeout(() => {
      setIsInitializing(false);
    }, 1000);
  }, []);

  const capture = useCallback(async () => {
    if (webcamRef.current && !isCapturing) {
      setIsCapturing(true);
      setError(null);
      try {
        const imageSrc = webcamRef.current.getScreenshot({
          width: 2048,
          height: 1536
        });
        if (imageSrc) {
          setImgSrc(imageSrc);
        } else {
          throw new Error("Failed to capture image");
        }
      } catch (err) {
        handleError("Failed to capture photo. Please try again.");
      } finally {
        setIsCapturing(false);
      }
    }
  }, [webcamRef, isCapturing]);

  const confirmCapture = async () => {
    if (!imgSrc || isUploading) return;

    setIsUploading(true);
    try {
      // Convert base64 to blob
      const base64Data = imgSrc.split(',')[1];
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
      
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      
      const blob = new Blob(byteArrays, { type: 'image/jpeg' });
      const file = new File([blob], `photo_${Date.now()}.jpg`, { type: 'image/jpeg' });
      await onCapture([file]);
      onClose();
    } catch (error) {
      console.error('Failed to process capture:', error);
      handleError("Failed to process photo. Please try again.");
      setIsUploading(false);
    }
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isUploading) return;

    const files = Array.from(event.target.files || []);
    if (files.length > 0) {
      setIsUploading(true);
      try {
        await onCapture(files);
        onClose();
      } catch (error) {
        console.error('Failed to upload files:', error);
        setIsUploading(false);
      }
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => !isUploading && onClose()}
      TransitionComponent={Slide}
      disableEscapeKeyDown={isUploading}
      TransitionProps={{
        onExited: () => {
          // Ensure body scroll is restored when dialog animation completes
          document.body.style.overflow = '';
          document.body.style.position = '';
          document.body.style.width = '';
          document.body.style.height = '';
          document.body.style.touchAction = '';
        }
      }}
    >
      <Box
        sx={{
          height: '100%',
          backgroundColor: 'black',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          touchAction: 'none',
        }}
      >
        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              position: 'absolute', 
              top: 16, 
              left: 16, 
              right: 16, 
              zIndex: 1100 
            }}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        )}

        {/* Top controls */}
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 16px',
            zIndex: 2,
            touchAction: 'none',
          }}
        >
          <IconButton
            sx={{ color: 'white' }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          {isMobile && !imgSrc && (
            <IconButton
              sx={{ color: 'white' }}
              onClick={handleSwitchCamera}
              disabled={isInitializing}
            >
              <CameraswitchIcon />
            </IconButton>
          )}
        </Box>

        {/* Camera view */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden',
            touchAction: 'pinch-zoom',
          }}
        >
          {isInitializing ? (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 2,
              touchAction: 'none',
            }}>
              <CircularProgress sx={{ color: 'white' }} />
              <Typography sx={{ color: 'white' }}>Initializing camera...</Typography>
            </Box>
          ) : imgSrc ? (
            <img
              src={imgSrc}
              alt="capture"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
                touchAction: 'pinch-zoom',
              }}
            />
          ) : (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
              videoConstraints={videoConstraints}
              onUserMediaError={(err) => handleError("Failed to access camera. Please check permissions.")}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                touchAction: 'pinch-zoom',
              }}
              mirrored={facingMode === "user"}
            />
          )}
        </Box>

        {/* Bottom controls */}
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: 3,
            background: 'linear-gradient(transparent, rgba(0,0,0,0.8))',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            zIndex: 2,
            touchAction: 'none',
          }}
        >
          {imgSrc ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={confirmCapture}
                disabled={isUploading}
                sx={{ minWidth: 120 }}
              >
                {isUploading ? (
                  <>
                    Uploading...
                    <CircularProgress size={16} sx={{ ml: 1 }} />
                  </>
                ) : (
                  'Use Photo'
                )}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => !isUploading && setImgSrc(null)}
                disabled={isUploading}
                startIcon={<Replay />}
                sx={{ minWidth: 120 }}
              >
                Retake
              </Button>
            </>
          ) : (
            <>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept="image/*"
                multiple
                disabled={isUploading}
                style={{ display: 'none' }}
              />
              <Fab
                color="primary"
                onClick={() => !isUploading && fileInputRef.current?.click()}
                disabled={isInitializing || isUploading}
                sx={{ mx: 2 }}
              >
                <PhotoLibrary />
              </Fab>
              <Fab
                color="primary"
                onClick={capture}
                disabled={isInitializing || isCapturing || isUploading}
                sx={{ 
                  width: 72,
                  height: 72,
                  mx: 2,
                }}
              >
                {isCapturing ? (
                  <CircularProgress size={36} sx={{ color: 'white' }} />
                ) : (
                  <PhotoCamera sx={{ fontSize: 36 }} />
                )}
              </Fab>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
} 
import React, { createContext, useContext, useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useAuth } from './AuthContext';

interface NotificationContextType {
  joinEventGroup: (eventId: string) => void;
  leaveEventGroup: (eventId: string) => void;
}

const NotificationContext = createContext<NotificationContextType>({
  joinEventGroup: () => {},
  leaveEventGroup: () => {},
});

interface NotificationProviderProps {
  children: React.ReactNode;
}

export function NotificationProvider({ children }: NotificationProviderProps) {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const { isAuthenticated, isLoading, acquireToken } = useAuth();

  useEffect(() => {
    let hubConnection: HubConnection | null = null;

    const initializeSignalR = async () => {
      if (!isAuthenticated || isLoading) {
        return;
      }

      try {
        const token = await acquireToken();
        if (!token) {
          console.error('Failed to get token for SignalR connection');
          return;
        }

        const hubUrl = process.env.REACT_APP_API_BASE_URL?.replace(/\/api$/, '') + '/api/hubs/notifications';
        console.log('Connecting to SignalR hub at:', hubUrl);

        hubConnection = new HubConnectionBuilder()
          .withUrl(hubUrl, {
            accessTokenFactory: () => token,
          })
          .withAutomaticReconnect()
          .build();

        await hubConnection.start();
        console.log('SignalR Connected');
        setConnection(hubConnection);
      } catch (err) {
        console.error('Failed to connect to SignalR hub:', err);
      }
    };

    initializeSignalR();

    return () => {
      if (hubConnection) {
        hubConnection.stop();
      }
    };
  }, [isAuthenticated, isLoading, acquireToken]);

  const joinEventGroup = async (eventId: string) => {
    if (connection) {
      try {
        await connection.invoke('JoinEventGroup', eventId);
        console.log('Joined event group:', eventId);
      } catch (err) {
        console.error('Error joining event group:', err);
      }
    }
  };

  const leaveEventGroup = async (eventId: string) => {
    if (connection) {
      try {
        await connection.invoke('LeaveEventGroup', eventId);
        console.log('Left event group:', eventId);
      } catch (err) {
        console.error('Error leaving event group:', err);
      }
    }
  };

  return (
    <NotificationContext.Provider value={{ joinEventGroup, leaveEventGroup }}>
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotifications() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
} 
import React, { useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import {
  Home as HomeIcon,
  Event as EventIcon,
  PhotoCamera as PhotoCameraIcon,
  MoreVert as MoreVertIcon,
  Brightness4 as DarkModeIcon,
  Brightness7 as LightModeIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { photoApi, eventApi } from '../api/client';
import PhotoCaptureMenu from './PhotoCaptureMenu';

interface NavigationItem {
  label: string;
  icon: React.ReactNode;
  path?: string;
  onClick?: () => void;
}

export default function Layout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { mode, toggleColorMode } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  // Menu state
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [showPhotoCapture, setShowPhotoCapture] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  const handleCapture = useCallback(async (files: File[]) => {
    try {
      // Get the first ongoing event
      const ongoingEvents = await queryClient.fetchQuery(['events', 'ongoing'], 
        () => eventApi.getEvents('ongoing')
      );

      if (!ongoingEvents?.length) {
        enqueueSnackbar('No ongoing events found. Create an event first.', { variant: 'warning' });
        navigate('/events');
        return;
      }

      const eventId = ongoingEvents[0].id;

      await Promise.all(
        files.map(file => photoApi.uploadPhoto(eventId, file))
      );
      
      enqueueSnackbar('Photos uploaded successfully', { variant: 'success' });
      queryClient.invalidateQueries(['photos', 'recent']);
      queryClient.invalidateQueries(['eventAlbums', eventId]);
    } catch (error) {
      console.error('Upload failed:', error);
      enqueueSnackbar('Failed to upload photos', { variant: 'error' });
      throw error;
    }
  }, [enqueueSnackbar, queryClient, navigate]);

  const navigationItems: NavigationItem[] = [
    { label: 'Feed', icon: <HomeIcon />, path: '/' },
    { 
      label: 'Take Photo', 
      icon: <PhotoCameraIcon />, 
      onClick: () => setShowPhotoCapture(true)
    },
    { label: 'Events', icon: <EventIcon />, path: '/events' },
  ];

  const handleNavigation = (value: string | undefined) => {
    if (!value) return;
    
    const item = navigationItems.find(item => 
      'path' in item ? item.path === value : item.label === value
    );
    
    if (item?.path) {
      navigate(item.path);
    } else if (item?.onClick) {
      item.onClick();
    }
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          mb: '56px', // Height of BottomNavigation
        }}
      >
        {children}
      </Box>

      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }}
        elevation={3}
      >
        <BottomNavigation
          value={location.pathname}
          onChange={(_, newValue) => handleNavigation(newValue)}
          showLabels
        >
          {navigationItems.map((item) => (
            <BottomNavigationAction
              key={item.label}
              label={item.label}
              icon={item.icon}
              value={item.path || item.label}
            />
          ))}
          <BottomNavigationAction
            label="More"
            icon={<MoreVertIcon />}
            onClick={handleMenuOpen}
          />
        </BottomNavigation>
      </Paper>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => { toggleColorMode(); handleMenuClose(); }}>
          <ListItemIcon>
            {mode === 'light' ? <DarkModeIcon fontSize="small" /> : <LightModeIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText>
            {mode === 'light' ? 'Dark Mode' : 'Light Mode'}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>

      <PhotoCaptureMenu
        open={showPhotoCapture}
        onClose={() => setShowPhotoCapture(false)}
        onCapture={handleCapture}
      />
    </Box>
  );
} 
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS } from 'date-fns/locale';

interface DateTimeProviderProps {
  children: React.ReactNode;
}

export function DateTimeProvider({ children }: DateTimeProviderProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      {children}
    </LocalizationProvider>
  );
} 
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Typography,
  IconButton,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  SelectAll as SelectAllIcon,
  ClearAll as ClearAllIcon,
  PhotoAlbum as PhotoAlbumIcon,
  Sort as SortIcon,
  Tag as TagIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { albumApi, eventApi } from '../api/client';
import { Album, Photo } from '../types/api';
import PhotoGrid from '../components/PhotoGrid';
import AlbumManagementDialog from '../components/AlbumManagementDialog';
import PhotoUpload from '../components/PhotoUpload';
import { usePhotoUpload } from '../hooks/usePhotoUpload';

export default function AlbumPage() {
  const { albumId } = useParams<{ albumId: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState<Set<string>>(new Set());
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [isManageDialogOpen, setIsManageDialogOpen] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState<'date' | 'name'>('date');

  const { data: album } = useQuery(
    ['album', albumId],
    () => (albumId ? albumApi.getAlbum(albumId) : null)
  );

  const { data: photos, isLoading: isLoadingPhotos } = useQuery(
    ['albumPhotos', albumId],
    () => (albumId ? albumApi.getAlbumPhotos(albumId) : [])
  );

  const { data: event } = useQuery(
    ['event', album?.eventId],
    () => (album?.eventId ? eventApi.getEvent(album.eventId) : null),
    {
      enabled: !!album?.eventId,
    }
  );

  const { isUploading, uploadProgress, uploadPhotos } = usePhotoUpload(
    event?.id || '',
    albumId
  );

  const deleteAlbumMutation = useMutation(
    (id: string) => albumApi.deleteAlbum(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['events']);
        navigate(`/events/${album?.eventId}`);
      },
    }
  );

  const handlePhotoClick = (photo: Photo) => {
    if (isSelectionMode) {
      const newSelected = new Set(selectedPhotos);
      if (selectedPhotos.has(photo.id)) {
        newSelected.delete(photo.id);
      } else {
        newSelected.add(photo.id);
      }
      setSelectedPhotos(newSelected);
    } else {
      navigate(`/photos/${photo.id}`);
    }
  };

  const handleDeleteAlbum = async () => {
    if (albumId) {
      await deleteAlbumMutation.mutateAsync(albumId);
    }
  };

  const handleSelectAll = () => {
    if (photos) {
      setSelectedPhotos(new Set(photos.map(p => p.id)));
    }
  };

  const handleClearSelection = () => {
    setSelectedPhotos(new Set());
    setIsSelectionMode(false);
  };

  const handleUploadPhotos = async (files: File[]) => {
    try {
      await uploadPhotos(files);
      queryClient.invalidateQueries(['albumPhotos', albumId]);
      setIsUploadDialogOpen(false);
    } catch (error) {
      console.error('Failed to upload photos:', error);
    }
  };

  const sortedPhotos = React.useMemo(() => {
    if (!photos) return [];
    return [...photos].sort((a, b) => {
      if (sortOrder === 'date') {
        return new Date(b.takenAt).getTime() - new Date(a.takenAt).getTime();
      }
      return a.title.localeCompare(b.title);
    });
  }, [photos, sortOrder]);

  const speedDialActions = [
    { icon: <SelectAllIcon />, name: 'Select All', onClick: handleSelectAll },
    { icon: <ClearAllIcon />, name: 'Clear Selection', onClick: handleClearSelection },
    { icon: <SortIcon />, name: 'Toggle Sort', onClick: () => setSortOrder(prev => prev === 'date' ? 'name' : 'date') },
    { icon: <PhotoAlbumIcon />, name: 'Manage Albums', onClick: () => setIsManageDialogOpen(true) },
  ];

  if (!album || !photos) {
    return <Typography>Loading album...</Typography>;
  }

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <IconButton
          onClick={() => navigate(`/events/${album.eventId}`)}
          sx={{ mr: 2 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" component="h1" sx={{ flexGrow: 1 }}>
          {album.name}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsUploadDialogOpen(true)}
          sx={{ mr: 2 }}
        >
          Add Photos
        </Button>
        <Button
          variant="outlined"
          startIcon={isSelectionMode ? <ClearAllIcon /> : <SelectAllIcon />}
          onClick={() => setIsSelectionMode(!isSelectionMode)}
          sx={{ mr: 2 }}
        >
          {isSelectionMode ? 'Cancel Selection' : 'Select Photos'}
        </Button>
        <IconButton onClick={() => setIsDeleteDialogOpen(true)} color="error">
          <DeleteIcon />
        </IconButton>
      </Box>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Album Details
        </Typography>
        <Typography color="textSecondary" paragraph>
          {album.description}
        </Typography>
        {event && (
          <Typography variant="body2" color="textSecondary">
            Event: {event.name}
          </Typography>
        )}
        <Typography variant="body2" color="textSecondary">
          Created: {format(new Date(album.createdAt), 'PPP')}
        </Typography>
      </Paper>

      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">Photos ({photos.length})</Typography>
        <Typography variant="body2" color="textSecondary">
          Sorted by: {sortOrder === 'date' ? 'Date Taken' : 'Name'}
        </Typography>
      </Box>

      <PhotoGrid
        photos={sortedPhotos}
        onPhotoClick={handlePhotoClick}
        selectable={isSelectionMode}
        selectedPhotos={selectedPhotos}
      />

      <SpeedDial
        ariaLabel="Photo management"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        open={isSelectionMode && selectedPhotos.size > 0}
      >
        {speedDialActions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onClick}
          />
        ))}
      </SpeedDial>

      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Delete Album</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this album? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteAlbum} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {isManageDialogOpen && (
        <AlbumManagementDialog
          open={isManageDialogOpen}
          onClose={() => {
            setIsManageDialogOpen(false);
            handleClearSelection();
          }}
          eventId={album.eventId}
          currentAlbumId={album.id}
          selectedPhotos={photos.filter(p => selectedPhotos.has(p.id))}
        />
      )}

      <Dialog
        open={isUploadDialogOpen}
        onClose={() => setIsUploadDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add Photos to Album</DialogTitle>
        <DialogContent>
          <PhotoUpload
            onUpload={handleUploadPhotos}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsUploadDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
} 
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Event } from '../types/api';

interface CreateEventDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (event: Omit<Event, 'id' | 'albumIds' | 'createdAt' | 'updatedAt'>) => void;
}

export default function CreateEventDialog({ open, onClose, onSubmit }: CreateEventDialogProps) {
  const [event, setEvent] = useState({
    name: '',
    description: '',
    location: '',
    startTime: new Date(),
    endTime: new Date(Date.now() + 3600000), // 1 hour from now
    organizerId: '',
  });

  const handleSubmit = () => {
    onSubmit({
      ...event,
      startTime: event.startTime.toISOString(),
      endTime: event.endTime.toISOString(),
    });
    setEvent({
      name: '',
      description: '',
      location: '',
      startTime: new Date(),
      endTime: new Date(Date.now() + 3600000),
      organizerId: '',
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create New Event</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
          <TextField
            label="Event Name"
            value={event.name}
            onChange={(e) => setEvent({ ...event, name: e.target.value })}
            fullWidth
            required
          />
          <TextField
            label="Location"
            value={event.location}
            onChange={(e) => setEvent({ ...event, location: e.target.value })}
            fullWidth
          />
          <TextField
            label="Description"
            value={event.description}
            onChange={(e) => setEvent({ ...event, description: e.target.value })}
            multiline
            rows={3}
            fullWidth
          />
          <DateTimePicker
            label="Start Time"
            value={event.startTime}
            onChange={(newValue) => newValue && setEvent({ ...event, startTime: newValue })}
          />
          <DateTimePicker
            label="End Time"
            value={event.endTime}
            onChange={(newValue) => newValue && setEvent({ ...event, endTime: newValue })}
            minDateTime={event.startTime}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!event.name || !event.startTime || !event.endTime}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
} 
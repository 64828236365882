import axios, { AxiosError } from 'axios';
import { Event, Album, Photo, Comment } from '../types/api';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export class ApiError extends Error {
  constructor(
    message: string,
    public status?: number,
    public code?: string
  ) {
    super(message);
    this.name = 'ApiError';
  }
}

const handleError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<{ message?: string; code?: string }>;
    throw new ApiError(
      axiosError.response?.data?.message || axiosError.message,
      axiosError.response?.status,
      axiosError.response?.data?.code
    );
  }
  throw error;
};

let tokenRefreshPromise: Promise<string> | null = null;
let currentToken: string | null = null;

export const setAuthToken = (token: string) => {
  currentToken = token;
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const clearAuthToken = () => {
  currentToken = null;
  tokenRefreshPromise = null;
  delete api.defaults.headers.common['Authorization'];
};

export function setupTokenRefresh(acquireToken: () => Promise<string>) {
  // Add request interceptor to the api instance
  api.interceptors.request.use(
    async (config) => {
      // Skip token refresh for token acquisition requests
      if (config.url?.includes('/token')) {
        return config;
      }

      try {
        // If there's no token refresh in progress, start one
        if (!tokenRefreshPromise) {
          tokenRefreshPromise = acquireToken().finally(() => {
            tokenRefreshPromise = null;
          });
        }

        // Wait for the token refresh to complete
        const token = await tokenRefreshPromise;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      } catch (error) {
        console.error('Failed to refresh token:', error);
        tokenRefreshPromise = null;
        throw error;
      }
    },
    (error) => Promise.reject(error)
  );

  // Add response interceptor to handle 401 errors
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          // Clear existing token refresh promise
          tokenRefreshPromise = null;
          
          // Force a new token acquisition
          const token = await acquireToken();
          if (token) {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return api(originalRequest);
          }
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );
}

export const eventApi = {
  getEvents: (filter: 'upcoming' | 'ongoing' | 'past') =>
    api.get<Event[]>(`/events?filter=${filter}`)
      .then(res => res.data)
      .catch(handleError),

  getEvent: (id: string) =>
    api.get<Event>(`/events/${id}`)
      .then(res => res.data)
      .catch(handleError),

  createEvent: (event: Omit<Event, 'id' | 'albumIds' | 'createdAt' | 'updatedAt'>) =>
    api.post<Event>('/events', event)
      .then(res => res.data)
      .catch(handleError),

  updateEvent: (id: string, event: Partial<Event>) =>
    api.put<Event>(`/events/${id}`, event)
      .then(res => res.data)
      .catch(handleError),

  deleteEvent: (id: string) =>
    api.delete(`/events/${id}`)
      .catch(handleError),

  getEventAlbums: (id: string) =>
    api.get<Album[]>(`/events/${id}/albums`)
      .then(res => res.data)
      .catch(handleError),

  createEventAlbum: (eventId: string, album: Omit<Album, 'id' | 'eventId' | 'photoIds' | 'createdAt' | 'updatedAt'>) =>
    api.post<Album>(`/events/${eventId}/albums`, album)
      .then(res => res.data)
      .catch(handleError),

  organizeEventPhotos: (eventId: string) =>
    api.post(`/events/${eventId}/organize`)
      .then(res => res.data)
      .catch(handleError),
};

export const albumApi = {
  getAlbum: (id: string) =>
    api.get<Album>(`/albums/${id}`)
      .then(res => res.data)
      .catch(handleError),

  getAlbumPhotos: (id: string) =>
    api.get<Photo[]>(`/albums/${id}/photos`)
      .then(res => res.data)
      .catch(handleError),

  updateAlbum: (id: string, album: Partial<Album>) =>
    api.put<Album>(`/albums/${id}`, album)
      .then(res => res.data)
      .catch(handleError),

  deleteAlbum: (id: string) =>
    api.delete(`/albums/${id}`)
      .catch(handleError),
};

export const photoApi = {
  uploadPhoto: (eventId: string, file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('eventId', eventId);
    return api.post<Photo>('/photos', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => res.data)
      .catch(handleError);
  },

  getPhoto: (id: string) =>
    api.get<Photo>(`/photos/${id}`)
      .then(res => res.data)
      .catch(handleError),

  updatePhoto: (id: string, photo: Partial<Photo>) =>
    api.put<Photo>(`/photos/${id}`, photo)
      .then(res => res.data)
      .catch(handleError),

  deletePhoto: (id: string) =>
    api.delete(`/photos/${id}`)
      .catch(handleError),

  movePhoto: (id: string, albumId: string) =>
    api.put<Photo>(`/photos/${id}/move`, JSON.stringify(albumId), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.data)
      .catch(handleError),

  likePhoto: (id: string) =>
    api.post<Photo>(`/photos/${id}/like`)
      .then(res => res.data)
      .catch(handleError),

  unlikePhoto: (id: string) =>
    api.delete(`/photos/${id}/like`)
      .then(res => res.data)
      .catch(handleError),

  addComment: (id: string, text: string) =>
    api.post<Comment>(`/photos/${id}/comments`, { text })
      .then(res => res.data)
      .catch(handleError),

  deleteComment: (photoId: string, commentId: string) =>
    api.delete(`/photos/${photoId}/comments/${commentId}`)
      .catch(handleError),

  sharePhoto: (id: string) =>
    api.post<Photo>(`/photos/${id}/share`)
      .then(res => res.data)
      .catch(handleError),
}; 
import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Divider,
  Chip,
  Stack,
  Collapse,
  useTheme,
} from '@mui/material';
import {
  Favorite,
  FavoriteBorder,
  ChatBubbleOutline,
  Share,
  Send,
  LocationOn,
  Camera,
  AccessTime,
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import { Photo, Like, Comment } from '../types/api';
import { useAuth } from '../contexts/AuthContext';
import { useSnackbar } from 'notistack';
import { photoApi } from '../api/client';
import { useQueryClient } from 'react-query';
import { format } from 'date-fns';

interface PhotoSocialActionsProps {
  photo: Photo;
  onClose?: () => void;
}

export default function PhotoSocialActions({ photo, onClose }: PhotoSocialActionsProps) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [showComments, setShowComments] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [isLiking, setIsLiking] = useState(false);
  const [isCommenting, setIsCommenting] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const hasLiked = user ? photo.likes.some(like => like.userId === user.username) : false;
  const canShare = typeof navigator.share !== 'undefined';

  const invalidateQueries = async () => {
    await Promise.all([
      queryClient.invalidateQueries(['photo', photo.id]),
      queryClient.invalidateQueries('photos'),
      queryClient.invalidateQueries(['albumPhotos', photo.albumId]),
      queryClient.invalidateQueries('feed'),
    ]);
  };

  const getPhotoUrl = () => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/photos/${photo.id}`;
  };

  const handleLike = async () => {
    if (!user || isLiking) return;
    
    setIsLiking(true);
    try {
      const updatedPhoto = hasLiked 
        ? await photoApi.unlikePhoto(photo.id)
        : await photoApi.likePhoto(photo.id);
        
      // Optimistically update the UI
      queryClient.setQueryData(['photo', photo.id], updatedPhoto);
      
      enqueueSnackbar(hasLiked ? 'Photo unliked' : 'Photo liked', { variant: 'success' });
      await invalidateQueries();
    } catch (error) {
      console.error('Failed to like/unlike photo:', error);
      enqueueSnackbar('Failed to update like', { variant: 'error' });
    } finally {
      setIsLiking(false);
    }
  };

  const handleComment = async () => {
    const commentText = newComment.trim();
    if (!user || !commentText || isCommenting) return;

    setIsCommenting(true);
    setNewComment(''); // Clear input immediately to prevent duplicate submissions
    
    try {
      const comment = await photoApi.addComment(photo.id, commentText);
      
      // Optimistically update the UI
      const currentPhoto = queryClient.getQueryData<Photo>(['photo', photo.id]);
      if (currentPhoto) {
        queryClient.setQueryData(['photo', photo.id], {
          ...currentPhoto,
          comments: [...currentPhoto.comments, comment],
        });
      }
      
      enqueueSnackbar('Comment added', { variant: 'success' });
      await invalidateQueries();
    } catch (error) {
      console.error('Failed to add comment:', error);
      enqueueSnackbar('Failed to add comment', { variant: 'error' });
      setNewComment(commentText); // Restore the comment text if submission failed
    } finally {
      setIsCommenting(false);
    }
  };

  const handleCommentKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !isCommenting) {
      event.preventDefault();
      handleComment();
    }
  };

  const handleShare = async () => {
    if (isSharing) return;

    setIsSharing(true);
    try {
      const photoUrl = getPhotoUrl();
      
      if (canShare) {
        await navigator.share({
          title: photo.title || 'Shared Photo',
          text: photo.description || 'Check out this photo!',
          url: photoUrl,
        });
        const updatedPhoto = await photoApi.sharePhoto(photo.id);
        
        // Optimistically update the UI
        queryClient.setQueryData(['photo', photo.id], updatedPhoto);
        
        await invalidateQueries();
        enqueueSnackbar('Photo shared', { variant: 'success' });
      } else {
        await navigator.clipboard.writeText(photoUrl);
        enqueueSnackbar('Photo URL copied to clipboard', { variant: 'success' });
      }
    } catch (error) {
      console.error('Failed to share photo:', error);
      if (error instanceof Error && error.name !== 'AbortError') {
        enqueueSnackbar('Failed to share photo', { variant: 'error' });
      }
    } finally {
      setIsSharing(false);
    }
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <IconButton 
          onClick={handleLike}
          disabled={!user || isLiking}
          color={hasLiked ? 'primary' : 'default'}
        >
          {isLiking ? (
            <CircularProgress size={24} />
          ) : hasLiked ? (
            <Favorite />
          ) : (
            <FavoriteBorder />
          )}
        </IconButton>
        <Typography variant="caption" color="text.secondary">
          {photo.likes.length}
        </Typography>

        <IconButton onClick={() => setShowComments(!showComments)}>
          <ChatBubbleOutline />
        </IconButton>
        <Typography variant="caption" color="text.secondary">
          {photo.comments.length}
        </Typography>

        <IconButton 
          onClick={handleShare}
          disabled={isSharing}
        >
          {isSharing ? (
            <CircularProgress size={24} />
          ) : (
            <Share />
          )}
        </IconButton>
        <Typography variant="caption" color="text.secondary">
          {photo.shareCount}
        </Typography>

        <Box sx={{ flex: 1 }} />

        <IconButton onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>

      <Collapse in={showDetails}>
        <Box sx={{ py: 1 }}>
          <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap sx={{ mb: 2 }}>
            {photo.location && (
              <Chip
                icon={<LocationOn />}
                label={photo.location}
                variant="outlined"
                size="small"
              />
            )}
            <Chip
              icon={<AccessTime />}
              label={format(new Date(photo.takenAt), 'PPp')}
              variant="outlined"
              size="small"
            />
            {photo.metadata.cameraModel && (
              <Chip
                icon={<Camera />}
                label={photo.metadata.cameraModel}
                variant="outlined"
                size="small"
              />
            )}
          </Stack>

          {(photo.metadata.lens || photo.metadata.focalLength || photo.metadata.aperture || photo.metadata.shutterSpeed || photo.metadata.iso) && (
            <Box sx={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
              gap: 1,
              bgcolor: theme.palette.background.default,
              p: 1,
              borderRadius: 1,
            }}>
              {photo.metadata.lens && (
                <Box>
                  <Typography variant="caption" color="text.secondary">Lens</Typography>
                  <Typography variant="body2">{photo.metadata.lens}</Typography>
                </Box>
              )}
              {photo.metadata.focalLength && (
                <Box>
                  <Typography variant="caption" color="text.secondary">Focal Length</Typography>
                  <Typography variant="body2">{photo.metadata.focalLength}mm</Typography>
                </Box>
              )}
              {photo.metadata.aperture && (
                <Box>
                  <Typography variant="caption" color="text.secondary">Aperture</Typography>
                  <Typography variant="body2">ƒ/{photo.metadata.aperture}</Typography>
                </Box>
              )}
              {photo.metadata.shutterSpeed && (
                <Box>
                  <Typography variant="caption" color="text.secondary">Shutter Speed</Typography>
                  <Typography variant="body2">1/{photo.metadata.shutterSpeed}s</Typography>
                </Box>
              )}
              {photo.metadata.iso && (
                <Box>
                  <Typography variant="caption" color="text.secondary">ISO</Typography>
                  <Typography variant="body2">{photo.metadata.iso}</Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Collapse>

      <Divider sx={{ my: 1 }} />

      {showComments && (
        <Box sx={{ mt: 2 }}>
          <Box sx={{ maxHeight: 200, overflowY: 'auto', mb: 2 }}>
            {photo.comments.map((comment: Comment) => (
              <Box key={comment.id} sx={{ mb: 1 }}>
                <Typography variant="subtitle2" component="span">
                  {comment.userName}:
                </Typography>
                <Typography component="span" sx={{ ml: 1 }}>
                  {comment.text}
                </Typography>
              </Box>
            ))}
          </Box>

          {user && (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                fullWidth
                size="small"
                placeholder="Add a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                onKeyPress={handleCommentKeyPress}
                disabled={isCommenting}
                multiline
                maxRows={4}
              />
              <Button
                variant="contained"
                onClick={handleComment}
                disabled={!newComment.trim() || isCommenting}
                sx={{ minWidth: 100 }}
              >
                {isCommenting ? (
                  <>
                    Sending
                    <CircularProgress size={16} sx={{ ml: 1 }} />
                  </>
                ) : (
                  <>
                    Send
                    <Send sx={{ ml: 1, fontSize: 16 }} />
                  </>
                )}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
} 
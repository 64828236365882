import React from 'react';
import { useMsal } from '@azure/msal-react';
import {
  Box,
  Button,
  Container,
  Typography,
  Paper,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Microsoft as MicrosoftIcon } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { InstallPWA } from '../components/InstallPWA';

export default function LoginPage() {
  const { instance } = useMsal();
  const { isLoading, error, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await instance.loginRedirect({
        scopes: [process.env.REACT_APP_AZURE_AD_SCOPES ?? ''],
      });
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  // Redirect to events page if already authenticated
  React.useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate('/events', { replace: true });
    }
  }, [isLoading, isAuthenticated, navigate]);

  if (isLoading || isAuthenticated) {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2" component="h1" gutterBottom>
          Spark
        </Typography>
        <Typography variant="h5" color="textSecondary" align="center" gutterBottom>
          Share and organize your event photos with colleagues
        </Typography>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Sign in to continue
          </Typography>

          {error && (
            <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
              {error.message}
            </Alert>
          )}

          <Button
            variant="contained"
            size="large"
            startIcon={<MicrosoftIcon />}
            onClick={handleLogin}
            fullWidth
          >
            Sign in with Microsoft
          </Button>

          <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
            Use your work or school account to sign in
          </Typography>
        </Paper>

        <Typography variant="body2" color="textSecondary" align="center">
          &copy; {new Date().getFullYear()} Spark. All rights reserved.
        </Typography>
      </Box>
      <InstallPWA />
    </Container>
  );
} 